import { Center, Link, Icon, useColorModeValue } from '@chakra-ui/react';
import { BsGithub } from 'react-icons/bs';

const GitHubButton = () => {
  const bg = useColorModeValue('gray.100', 'rgba(255,255,255,0.08)');
  const bgHover = useColorModeValue('gray.200', 'rgba(255,255,255,0.16)');
  const iconColor = useColorModeValue('black', 'white');

  return (
    <Link
      href='https://github.com/howardding2000'
      _hover={{ color: iconColor }}
    >
      <Center
        height='8'
        w='10'
        bgColor={bg}
        borderRadius='.4rem'
        _hover={{ bgColor: bgHover }}
      >
        <Icon as={BsGithub} />
      </Center>
    </Link>
  );
};

export default GitHubButton;
