import React, { useRef } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { MdOutlineMail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
// import { BsWhatsapp } from 'react-icons/bs';
import {
  Box,
  Heading,
  Link,
  Icon,
  FormControl,
  Input,
  Textarea,
  Button,
  Grid,
  Flex,
  useToast,
} from "@chakra-ui/react";
import Section from "../layout/Section";
import emailjs from "@emailjs/browser";

const ContactMe = () => {
  const contactOption = [
    {
      icon: MdOutlineMail,
      title: "Email",
      desc: "howardding2000@gmail.com",
      link: "mailto:howardding2000@gmail.com",
    },
    {
      icon: RiMessengerLine,
      title: "Messenger",
      desc: "howardding2000",
      link: "https://m.me/howard.ding2000",
    },
    // {
    //   icon: BsWhatsapp,
    //   title: 'WhatsApp',
    //   desc: '',
    //   // link: 'https://api.whatsapp.com/send?phone=+15145859604',
    //   link: '#',
    // },
  ];

  const form = useRef();
  const toast = useToast();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xz7ebjm",
        "template_n9gjaei",
        form.current,
        "MKR-ODNWy3-PkuHqe"
      )
      .then(
        (result) => {
          toast({
            title: `${result.text}`,
            description: "Your email has been sent successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          form.current.reset();
        },
        (error) => {
          toast({
            title: `error`,
            description:
              "Sorry, an error occurred on servers, please try again later.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      );
  };

  return (
    <Section id='contact'>
      <AnimationOnScroll
        animateIn='animate__fadeInDown'
        animateOut='animate__fadeOutUp'
      >
        <Heading size='md' variant='section__heading__h5'>
          Get In Touch
        </Heading>
        <Heading size='xl' variant='section__heading__h2'>
          Contact Me
        </Heading>
      </AnimationOnScroll>
      <Grid
        className='container contact_container'
        w={{ base: "90%", md: "65%" }}
        gridTemplateColumns={{ base: "1fr", lg: "30% 58%" }}
        gap={{ base: "2rem", lg: "12%" }}
        m='0 auto'
        transition='var(--transition)'
      >
        <Flex className='contact__options' flexDirection='column' gap='1.2rem'>
          {contactOption.map((item) => (
            <Box
              className='contact__option'
              key={item.title}
              bg='var(--color-bg-variant)'
              p='1.2rem'
              borderRadius='1.2rem'
              text-align='center'
              border='1px solid transparent'
              transition='var(--transition)'
              _hover={{
                bg: "transparent",
                border: "1px solid var(--color-bg-variant)",
              }}
            >
              <Icon as={item.icon} fontSize='1.5rem' mb='0.5rem' />
              <Heading size='md'>{item.title}</Heading>
              <Heading size='sm'>{item.desc}</Heading>
              <Link
                href={item.link}
                target='_blank'
                display='inline-block'
                mt='0.7rem'
                fontSize='0.8rem'
              >
                Send a message
              </Link>
            </Box>
          ))}
        </Flex>
        <form ref={form} onSubmit={sendEmail}>
          <FormControl display='flex' flexDirection='column' gap='1.2rem'>
            <Input
              type='text'
              name='name'
              placeholder='Your Full Name'
              focusBorderColor='orange.500'
              required
            />
            <Input
              type='email'
              name='email'
              placeholder='Your Email'
              focusBorderColor='orange.500'
              required
            />
            <Textarea
              name='message'
              rows='7'
              placeholder='Your Message'
              focusBorderColor='orange.500'
              resize='none'
              required
            />
            <Button
              type='submit'
              variant='primary'
              h='14'
              m='0.5rem'
              fontWeight='400'
              fontSize='xl'
            >
              Send Message
            </Button>
          </FormControl>
        </form>
      </Grid>
    </Section>
  );
};

export default ContactMe;
