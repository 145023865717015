import React from "react";
import { Box } from "@chakra-ui/react";
const Section = (props) => {
  const { size, variant, ...rest } = props;
  // const styles = useStyleConfig('Section', { size, variant });
  return (
    <Box
      // as='section'
      // sx={styles}
      pt='8rem'
      textAlign='center'
      {...rest}
    >
      {props.children}
    </Box>
  );
};

export default Section;
