import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Center, Heading, Img } from "@chakra-ui/react";
import Section from "../layout/Section";
import SocialSideBar from "../layout/SocialSideBar";
import ScrollDown from "../layout/ScrollDown";
import CTA from "./CTA";
import imgSvg from "../../assets/programming.svg";

const MyWelcome = () => {
  return (
    <Section position='relative' id='welcome'>
      <SocialSideBar />
      <ScrollDown />
      <AnimationOnScroll
        animateIn='animate__fadeInDown'
        animateOut='animate__fadeOutUp'
      >
        <Heading size='md' varianty='section__heading__h5'>
          Hi, I am
        </Heading>
        <Heading size='2xl' variant='section__heading__h1'>
          Howard Ding
        </Heading>
        <Heading size='md' variant='section__heading__h5'>
          Front End Developer
        </Heading>
      </AnimationOnScroll>
      <CTA />
      <Center mt='5rem'>
        <Img src={imgSvg} w='28rem' />
      </Center>
    </Section>
  );
};

export default MyWelcome;
