const Heading = {
  // style object for base or default style
  baseStyle: {
    fontFamily: 'Poppins, sans-serif',
  },
  // styles for different sizes ("sm", "md", "lg")
  //   sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    section__heading__h1: {
      my: '0.5rem',
      color: 'var(--color-primary)',
    },
    section__heading__h2: {
      mb: '3rem',
      color: 'var(--color-primary)',
    },
    section__heading__h5: {
      fontWeight: '500',
      color: 'var(--color-mode)',
    },
  },
  // default values for `size` and `variant`
  //   defaultProps: {
  //     size: '',
  //     variant: '',
  //   },
};

export default Heading;
