import { Center, Link, Icon, useColorModeValue } from '@chakra-ui/react';


const SocialButton = (props) => {
  const bg = useColorModeValue('orange.400', 'orange.600');
  const bgHover = useColorModeValue('gray.300', 'rgba(255,255,255,0.16)');
  const iconHover = useColorModeValue('black', 'white');
  const {href, icon,...rest} = props;
  return (
    <Link href={href} {...rest}>
      <Center
        height='8'
        w='10'
        mb='0.5rem'
        bg={bg}
        borderRadius='6px'
        color='white'
        _hover={{ bg: bgHover, color:iconHover}}
      >
        <Icon as={icon} />
      </Center>
    </Link>
  );
};

export default SocialButton;
