// import Banner from "../components/home/Banner";
import Welcome from "../components/home/Welcome";
import Nav from "../components/home/Nav";
import AboutMe from "../components/home/AboutMe";
import MyExperience from "../components/home/MyExperience";
import ContactMe from "../components/home/ContactMe";
import Portfolio from "../components/home/Portfolio";
const Home = () => {
  return (
    <>
      <Nav />
      {/* <Banner /> */}
      <Welcome />
      <AboutMe />
      <MyExperience />
      <Portfolio />
      <ContactMe />
      {/* <SiteIntro /> */}
    </>
  );
};

export default Home;
