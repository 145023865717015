import React from 'react';
import { BsPatchCheckFill } from 'react-icons/bs';
import { Box, Heading, Grid, Flex, Icon, Text } from '@chakra-ui/react';
const MyExperienceCard = (props) => {
  return (
    <Box
      className='experience__frontend'
      bg='var(--color-bg-variant)'
      p={{ base:'1rem',md: '2rem', lg: '2.4rem 5rem' }}
      w={{ base: '100%', md: '80%', lg: '100%' }}
      m={{ base: '0 auto', lg: '' }}
      borderRadius='2rem'
      border='1px solid transparent'
      transition='var(--transition)'
      _hover={{
        bg: 'transparent',
        borderColor: 'var(--color-bg-variant)',
        cursor: 'default',
      }}
    >
      <Heading
        size='md'
        mb='2rem'
        color='var(--color-primary)'
        textAlign='center'
      >
        {props.title}
      </Heading>
      <Grid
        className='experience__content'
        gridTemplateColumns='1fr 1fr'
        justifyContent='center'
        p={{ base: '1rem', lg: '1rem' }}
        rowGap='2rem'
      >
        {props.items.map((item) => (
          <Flex
            className='experience__details'
            key={item.title}
            pl={{ base: '2rem', lg: '0' }}
            // alignItems='center'
            gap='1rem'
          >
            <Icon as={BsPatchCheckFill} mt='6px' color='var(--color-primary)' />
            <Box>
              <Heading size='sm'>{item.title}</Heading>
              <Text className='text-light'>{item.exp}</Text>
            </Box>
          </Flex>
        ))}
      </Grid>
    </Box>
  );
};

export default MyExperienceCard;
