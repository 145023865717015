import React from 'react';
import { VStack, Box } from '@chakra-ui/react';
import { BsGithub, BsFacebook } from 'react-icons/bs';
import { SiIndeed } from 'react-icons/si';
import SocialButton from '../UI/SocialButton';

const SocialSideBar = () => {
  const gbbLink = 'https://github.com/howardding2000';
  const fbLink = 'hhttps://www.facebook.com/profile.php?id=100008988763033';
  const indeedLink = 'https://my.indeed.com/p/394mg17';

  return (
    <VStack
    display={{ base: 'none', md: 'flex' }}
      position='absolute'
      left='0'
      bottom='3rem'
      justifyItems='center'
    >
      <SocialButton href={gbbLink} icon={BsGithub} target='_blank' />
      <SocialButton href={fbLink} icon={BsFacebook} target='_blank' />
      <SocialButton href={indeedLink} icon={SiIndeed} target='_blank' />
      <Box bg='var(--color-primary)' h='6rem' w='2px' />
    </VStack>
  );
};

export default SocialSideBar;
