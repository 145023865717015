import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Grid, Heading } from "@chakra-ui/react";
import Section from "../layout/Section";
import PortfolioCard from "./PortfolioCard";

const Portfolio = () => {
  const items = {};
  return (
    <Section id='portfolio'>
      <AnimationOnScroll
        animateIn='animate__fadeInDown'
        animateOut='animate__fadeOutUp'
      >
        <Heading size='md' variant='section__heading__h5'>
          My Recent Work
        </Heading>
        <Heading size='xl' variant='section__heading__h2'>
          Portfolio
        </Heading>
      </AnimationOnScroll>
      <Grid
        className='container experience_container'
        gridTemplateColumns={{ base: "1fr", md: "1fr 1fr",lg: "repeat(3,1fr)" }}
        justifyContent='center'
        gap={{ base: "1rem", md: "2rem" }}
        textAlign='left'
      >
        <PortfolioCard title='Frontend Development' items={items} />
        <PortfolioCard title='Frontend Development' items={items} />
        <PortfolioCard title='Backend Development' items={items} />
      </Grid>
    </Section>
  );
};

export default Portfolio;
