import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {
  Heading,
  Text,
  Box,
  Image,
  Icon,
  Grid,
  Link,
  Button,
} from "@chakra-ui/react";
import { FaAward, FaToolbox, FaCode } from "react-icons/fa";
import Section from "../layout/Section";
import ME from "../../assets/me_2.jpg";

const MyAboutMe = () => {
  const aboutList = [
    {
      icon: FaAward,
      title: "Experience",
      discription: "1+ Years Working",
    },
    {
      icon: FaToolbox,
      title: "Skills",
      discription: "Design and Develop for Front End",
    },
    {
      icon: FaCode,
      title: "Status",
      discription: "Looking For a Job",
    },
  ];
  return (
    <Section id='aboutme'>
      <AnimationOnScroll
        animateIn='animate__fadeInDown'
        animateOut='animate__fadeOutUp'
      >
        <Heading size='md' variant='section__heading__h5'>
          Get to Know
        </Heading>
        <Heading size='xl' variant='section__heading__h2'>
          About me
        </Heading>
      </AnimationOnScroll>
      <Grid
        className='container about_container'
        gridTemplateColumns={{ base: "1fr", xl: "25% 45%" }}
        justifyContent='center'
        gap={{ base: "0", lg: "10%" }}
      >
        <Box
          className='about__me'
          w={{ base: "60%", md: "40%", xl: "100%" }}
          m={{ base: "0 auto 3rem", md: "2rem auto 4rem", xl: "auto" }}
          aspect-ratio={1 / 1}
          borderRadius='2rem'
          bgGradient='linear-gradient(to-br, transparent, var(--color-primary), transparent)'
        >
          <Box
            className='about__me-image'
            borderRadius='2rem'
            overflow='hidden'
            transform='rotate(10deg)'
            transition='var(--transition)'
            _hover={{
              transform: "rotate(0)",
            }}
          >
            <Image src={ME} alt='about Image' w='100%' h='100%' />
          </Box>
        </Box>

        <Box
          className='about_content'
          textAlign={{ base: "center", md: "left" }}
        >
          <Grid
            className='about_cards'
            gridTemplateColumns={{
              base: "1fr 1fr",
              lg: "repeat(3, 1fr)",
            }}
            gap={{ base: "1rem", md: "1.5rem" }}
          >
            {aboutList.map((item) => {
              return (
                <Box
                  key={item.title}
                  className='about_card'
                  bg='var(--color-bg-variant)'
                  border='1px solid transparent'
                  borderRadius='1rem'
                  p={{ base: "1rem", md: "1.5rem" }}
                  textAlign='center'
                  transition='var(--transition)'
                  _hover={{
                    bg: "transparent",
                    borderColor: "var(--color-primary)",
                    cursor: "default",
                  }}
                >
                  <Icon
                    color='var(--color-primary)'
                    as={item.icon}
                    fontSize='1.4rem'
                    mb='0.8rem'
                  />
                  <Heading size='md' mb='0.8rem'>
                    {item.title}
                  </Heading>
                  <Text fontSize='1rem'>{item.discription}</Text>
                </Box>
              );
            })}
          </Grid>
          <Text
            m={{ base: "1.5rem 0", md: "1rem 0 1.5rem", xl: "2rem 0 2.6rem" }}
            color='var(--color-mode)'
          >
            Hi! My name is Howard Ding and I am a Web front-end developer. I
            like to express my ideas by writing code, which is as romantic as
            writing poetry. I hope you found purpose and fun in coding too.
          </Text>
          <Button
            href='#contact'
            as={Link}
            variant='primary'
            h='14'
            m='0.5rem'
            fontWeight='400'
            fontSize='xl'
          >
            Let's Talk
          </Button>
        </Box>
      </Grid>
    </Section>
  );
};

export default MyAboutMe;
