import { HStack } from '@chakra-ui/react';

import Logo from './Logo';
// import TopNavBar from './TopNavBar';
import ThemeButton from '../UI/ThemeButton';
import GitHubButton from '../UI/GitHubButton';

const TopBar = () => {
  return (
    <>
      <HStack justifyContent='space-between' m='5' alignItems='end'>
        <Logo />
        <HStack>
        <GitHubButton />
        <ThemeButton />
        {/* <TopNavBar /> */}
        </HStack>
      </HStack>
    </>
  );
};

export default TopBar;
