import { mode } from '@chakra-ui/theme-tools';

const Button = {
  // style object for base or default style
  baseStyle: {
    margin: 0,
    _hover: {
      textDecoration: 'none',
      outline: 'none',
    },

    _focus: { outline: 'none', boxShadow: 'none' },
    _active: { outline: 'none' },
  },
  // styles for different sizes ("sm", "md", "lg")
  //   sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: (props) => ({
      bg: 'var(--color-primary)',
      color: 'color.white',
      _hover: {
        color: 'var(--color-primary)',
        bg: mode('gray.200', 'rgba(255,255,255,0.16)')(props),
      },
    }),
  },
  // default values for `size` and `variant`
  //   defaultProps: {
  //     size: '',
  //     variant: '',
  //   },
};
export default Button;
