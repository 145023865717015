import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Grid, Heading } from "@chakra-ui/react";
import Section from "../layout/Section";
import MyExperienceCard from "./MyExperienceCard";

const MyExperience = () => {
  const feExp = [
    { title: "HTML", exp: "Intermediate" },
    { title: "CSS", exp: "Intermediate" },
    { title: "JavaScript", exp: "Intermediate" },
    { title: "React JS", exp: "Experienced" },
    { title: "TypeScript", exp: "Intermediate" },
    { title: "Webpack", exp: "Basic" },
  ];
  const beExp = [
    { title: "Node JS", exp: "Basic" },
    { title: "MySQL", exp: "Intermediate" },
    { title: "MongoDB", exp: "Intermediate" },
    { title: "Express", exp: "Intermediate" },
    { title: "JAVA", exp: "Intermediate" },
  ];
  return (
    <Section id='experience'>
      <AnimationOnScroll
        animateIn='animate__fadeInDown'
        animateOut='animate__fadeOutUp'
      >
        <Heading size='md' variant='section__heading__h5'>
          What Skills I have
        </Heading>
        <Heading size='xl' variant='section__heading__h2'>
          My Experience
        </Heading>
      </AnimationOnScroll>
      <Grid
        gridTemplateColumns={{ base: "1fr", lg: "40% 40%" }}
        justifyContent='center'
        gap={{ base: "1rem", md: "2rem" }}
        textAlign='left'
      >
        <MyExperienceCard title='Frontend Development' items={feExp} />
        <MyExperienceCard title='Backend Development' items={beExp} />
      </Grid>
    </Section>
  );
};

export default MyExperience;
