import { Box, Text } from '@chakra-ui/react';

const Logo = () => {
  return (
    <Box>
      <Text
        fontSize={{ base: 24, md: 32, lg: 36 }}
        fontWeight='bold'
        bgClip='text'
        bgGradient='linear(to-l, orange.400, orange.600)'
      >
        Buding.ca
      </Text>
    </Box>
  );
};

export default Logo;
