import React from 'react';
import { Box } from '@chakra-ui/react';

const ScrollDown = () => {
  return (
    <Box
      display={{ base: 'none', md: 'block' }}
      color='var(--color-primary)'
      position='absolute'
      right='-1rem'
      bottom='5rem'
      transform='rotate(90deg)'
      // fontWeight='bold'
    >
      Scroll Down
    </Box>
  );
};

export default ScrollDown;
