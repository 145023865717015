import "@fontsource/poppins/400.css";
import "animate.css/animate.min.css";
import { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ChakraProvider, Box } from "@chakra-ui/react";

import Header from "./components/layout/Header";
import Home from "./pages/Home";
import theme from "./theme/index";
import Footer from "./components/layout/Footer";

function App() {
  /**
   *  For solve FOUT issue
   *
   */
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    document.fonts.load("16px Poppins").then(() => setIsReady(true));
    // const timmer = setTimeout(setIsReady(true), 100);
    // return clearTimeout(timmer);
  }, []);
  return (
    isReady && (
      <ChakraProvider theme={theme}>
        <Box
          m='0 auto'
          maxW='container.xl'
          w={{ base: "90%", md: "86%", lg: "75%" }}
        >
          <Header />
          <Switch>
            <Route path='/' exact>
              <Redirect to='/home' />
            </Route>
            <Route path='/home'>
              <Home />
            </Route>
            {/* <Route path='/my-articles'>
              <MyArticles />
            </Route>
            <Route path='/my-demos'>
              <MyDemos />
            </Route> */}
            {/* <Route path="/about-me">
              <AboutMe />
            </Route> */}
          </Switch>
        </Box>
        <Footer />
      </ChakraProvider>
    )
  );
}

export default App;
