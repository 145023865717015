import React from 'react';
import { Center, Link, Button } from '@chakra-ui/react';
const CTA = () => {
  // path to CV
  const CV = '';
  return (
    <Center mt='2rem'>
      <Link
        href={CV}
        as={Button}
        h='14'
        m='0.5rem'
        fontWeight='400'
        fontSize='xl'
      >
        Download CV
      </Link>
      <Button
        href='#contact'
        as={Link}
        variant='primary'
        h='14'
        m='0.5rem'
        fontWeight='400'
        fontSize='xl'
      >
        Let's Talk
      </Button>
    </Center>
  );
};

export default CTA;
