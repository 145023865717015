import { HStack, Icon, Link } from '@chakra-ui/react';
import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai';
import { BiBook, BiMessageSquareDetail } from 'react-icons/bi';
import React, { useState } from 'react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');

  const linkList = [
    {
      href: '#',
      icon: AiOutlineHome,
    },
    {
      href: '#aboutme',
      icon: AiOutlineUser,
    },
    {
      href: '#experience',
      icon: BiBook,
    },
    {
      href: '#contact',
      icon: BiMessageSquareDetail,
    },
  ];

  return (
    <HStack
      display={{ base: 'none', md: 'flex' }}
      bg='rgba(0,0,0,0.3)'
      w='max-content'
      p='0.7rem 1.7rem'
      zIndex='2'
      position='fixed'
      left='50%'
      transform='translateX(-50%)'
      bottom='2rem'
      gap='0.8rem'
      borderRadius='3rem'
      backdropFilter='blur(15px)'
    >
      {linkList.map((item) => {
        return (
          <Link
            key={item.href}
            href={item.href}
            variant='nav__link'
            bg={item.href === activeNav && 'rgba(0,0,0,0.3)'}
            transition='var(--transition)'
            onClick={() => setActiveNav(item.href)}
          >
            <Icon as={item.icon} m='.2rem ' />
          </Link>
        );
      })}
    </HStack>
  );
};

export default Nav;
