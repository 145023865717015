// theme/index.js
import { extendTheme, theme } from '@chakra-ui/react';
import '@fontsource/poppins';

// Global style overrides
import styles from './styles';
// Foundational style overrides
// import borders from './foundations/borders'

// Component style overrides
// import Button from './components/button'
import Link from './compoments/link';
import Button from './compoments/button';
import Heading from './compoments/heading';
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const extendThemes = {
  config: config,
  // set fonts to Poppins
  fonts: {
    ...theme.fonts,
    body: 'Poppins, sans-serif',
  },

   
  styles,
  //   borders,
  // Other foundational style overrides go here
  components: {
    Link,
    Button,
    Heading,
    // Other components go here
  },
};

export default extendTheme(extendThemes);
