const Link = {
  // style object for base or default style
  baseStyle: ({ colorMode }) => ({
    _hover: {
      textDecoration: 'none',
      outline: 'none',
      bg: colorMode === 'light' ? 'gray:300' : '',
      color: colorMode === 'light' ? 'orange.400' : 'orange.400',
    },

    _focus: { outline: 'none', boxShadow: 'none' },
    _active: { outline: 'none' },
  }),
  // styles for different sizes ("sm", "md", "lg")
  //   sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    nav__link: {
      bg: 'transparent',
      w: '2.5rem',
      h: '2.5rem',
      color: 'color.white',
      p: '0.5rem',
      borderRadius: '50%',
      _hover: {
        bg: 'rgba(0,0,0,0.3)',
      },
    },
    footer__link: {
      _hover: {
        color: 'var(--color-bg)',
      },
    },
  },
  // default values for `size` and `variant`
  //   defaultProps: {
  //     size: '',
  //     variant: '',
  //   },
};
export default Link;
