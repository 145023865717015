import { mode } from '@chakra-ui/theme-tools';

const styles = {
  
  global: (props) => ({
    '*': {
      margin: '0',
      padding: '0',
      boxSizing: 'border-box',
      listStyle: 'none',
      textDecoration: 'none',
      outline: 'none',
    },
    
    /**
     * 
      Orange 100
      #FEEBC8
      Orange 300
      #F6AD55
      Orange 400
      #ED8936
      Orange 500
      #DD6B20
     */
    ':root': {
      '--transition': 'all 500ms ease',
      // #1A202C : gray.800
      '--color-bg': mode('#FFFFFF', '#1A202C')(props),
      '--color-bg-variant': mode('#FEEBC8', '#9C4221')(props),
      '--color-primary': mode('#ED8936', '#DD6B20')(props),
      '--color-mode' : mode('#A0AEC0', '#718096')(props),
    },

    html: {
      scrollBehavior: 'smooth',
    },
    
    body: {
      minHeight: '100vh',
      bg: 'var(--color-bg)',
      // color: mode("gray.800", "color.white")(props),
    },
    
    '.text_gradient': {
      textAlign: 'center',
      fontWeight: 'bold',
      color: '',
      bgClip: 'text',
      bgGradient: 'linear(to-l, orange.300, orange.500)',
    },
  }),
};

export default styles;
