import { IconButton, Icon, useColorMode } from "@chakra-ui/react";
import { BsSun, BsMoonFill } from "react-icons/bs";

const ThemeButton = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <IconButton
      aria-label="theme"
      h="8"
      w="10"
      icon={
        colorMode === "light" ? <Icon as={BsMoonFill} /> : <Icon as={BsSun} />
      }
      _focus={{ boxShadow: "none" }}
      onClick={toggleColorMode}
    />
  );
};

export default ThemeButton;
