import React from "react";
import { Box, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";
import { FaFacebook } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoLogoTwitter } from "react-icons/io";

const Footer = () => {
  const pageLinks = [
    { title: "Home", href: "/home#" },
    { title: "About", href: "/home#aboutme" },
    { title: "Experience", href: "/home#experience" },
    // { title: 'Articles', href: '/my-articles#' },
    // { title: 'Demos', href: '/my-demos#' },
    { title: "Contact", href: "#contact" },
  ];

  const socialLinks = [
    { title: "facebook", href: "https://facebook.com", icon: FaFacebook },
    { title: "instagram", href: "https://instagram.com", icon: FiInstagram },
    { title: "twitte", href: "https://twitter.com", icon: IoLogoTwitter },
  ];
  return (
    <Box
      bg='var(--color-primary)'
      p='3rem 0'
      textAlign='center'
      fontSize='0.9rem'
      mt='7rem'
    >
      <Link
        href='#'
        _hover={{ color: "" }}
        fontSize='3xl'
        fontWeight='bold'
        color='orange.200'
        display='inline-block'
        mb='2rem'
      >
        Buding.ca
      </Link>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        flexWrap='wrap'
        justifyContent='center'
        gap={{ base: "1.5rem", md: "2rem" }}
        m='0 auto 3rem'
      >
        {pageLinks.map((item) => (
          <Box key={item.title}>
            <Link as={HashLink} to={item.href} variant='footer__link'>
              {item.title}
            </Link>
          </Box>
        ))}
      </Flex>
      <Flex
        justifyContent='center'
        gap='1rem'
        mb={{ base: "2.6rem", md: "4rem" }}
      >
        {socialLinks.map((item) => (
          <Link
            key={item.title}
            display='flex'
            href={item.href}
            bg='var(--color-bg)'
            color='gray.500'
            p='0.7rem'
            borderRadius='0.7rem'
            border='1px solid transparent'
            _hover={{
              bg: "transparent",
              color: "var(--color-bg)",
              borderColor: "var(--color-bg)",
            }}
          >
            <Icon as={item.icon} />
          </Link>
        ))}
      </Flex>
      <Box>
        <Text fontSize='sm' mb='4rem'>
          &copy; Buding.ca All right reserved
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
